import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useApiService from "../../services/api/api";
import { useUser } from "../../services/user/user";
import { useInfo } from "../../services/info/info";
import { useState } from "react";

const PayPal = ({pid, onSuccess}) => {
    const api = useApiService();
    const user = useUser();
    const info = useInfo();

    const [showError, setShowError] = useState(false);

    const initialOptions = {
        clientId: "Aeo93gB2Rj4fULaArjopqyAYv5YoeIT1PWXaTEXQXKa0GNrL9q-mutOOZrJn7jWY6Sx9kqm0TrROTZ2x",
        currency: "EUR",
        intent: "capture",
        vault:true
    };

    const createSubscription = (data, actions) => {
        setShowError(false);

        return actions.subscription.create({
            plan_id: pid
        });
    };

    const onApprove = (data, actions) => {
        let formData = new FormData();

        formData.append("email", user.getUser().email);
        formData.append("clientId", user.getUser().clientId);
        formData.append("subscriptionPlanId", pid);
        formData.append("infoJson", JSON.stringify(data));

        api.postDataFormData("payment/subscription", formData).then((data) => {
            if(data){
                user.setUserData(data);
                info.showInfo("Aktion erfolgreich.");
                onSuccess();
            }else{
                setShowError(true);
            }
        }).catch((error) => {
            setShowError(true);
        });
      };

    return (
        <>         
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons 
                    style={{ layout: "horizontal" }}
                    createSubscription={createSubscription}
                    onApprove={onApprove} 
                />
            </PayPalScriptProvider>
            {showError && (
                <div style={{color:'red', width:'100%', textAlign:'center'}}>
                    Es ist ein Fehler aufgetreten. Bitte melden Sie sich per E-Mail info@schoolai.app®.
                </div>
            )}
        </>

   );
};

export default PayPal;