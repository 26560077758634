import React, { useState } from 'react';
import "./Textquiz.css";
import { Row, Card, Form, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import useApiService from '../../../../services/api/api';
import useRoutingService from '../../../../services/routing/routing';
import useUtilsService from '../../../../services/utils/utils';
import GeneratingText from '../../../../components/GeneratingText/GeneratingText';
import Loading from '../../../../components/Loading/Loading';
import Niveau from '../../../../components/Niveau/Niveau';
import { useUser } from '../../../../services/user/user';
import GeneratePDFButton from '../../../../components/GeneratePDFButton/GeneratePDFButton';


const Textquiz = () => {
  const user = useUser();
  const routing = useRoutingService();
  const api = useApiService();
  const utils = useUtilsService();

  const [niveau, setNiveau] = useState("-1");

  const [theText, setTheText] = useState('');
  const [topic, setTopic] = useState('');

  const [loading, setLoading] = useState(false);

  const [quizText, setQuizText] = useState('');

  const [questionCount, setQuestionCount] = useState(-1);
  const [anwserCount, setAnswerCount] = useState(-1);

  const handleNiveau = (n) => {
      setNiveau(n);
      utils.fadeIn("gen");
  };

    const handleTheTextChange = () => {
        utils.fadeOut("quiz");
    }

    const handleTheTextChanged = (newText, topic) => {
        setTheText(newText);
        setTopic(topic);

        setQuizText("");

        utils.fadeIn("quiz");
    }


  const SafeHTMLComponent = ({ html }) => {
    return (
      <div>
        <div className='result' dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  };

  const showResult = () => {
    let qText = "";

    let qArr = quizText.split("$$$");

    for(var i = 0; i < qArr.length; i++){
      let qArrT = qArr[i].trim();

      console.log(qArrT);

      let qArrTQ = qArrT.split("?</b> ")[0];
      let qArrTA = qArrT.split("?</b> ")[1];

      if(!qArrTQ && !qArrTA){
        qArrTQ = qArrT.split("?</b>")[0];
        qArrTA = qArrT.split("?</b>")[1];
      }

      if(qArrTQ){
        qArrTQ = qArrTQ.replace((i+1)+". ", "").replace((i+1)+".", "").replace((i+1)+" ", "");
        qArrTQ = qArrTQ.replace("<b>", "<b>"+(i+1) + ". ");
      }

      if(qArrTA){
        qArrTA = qArrTA.replace("A)", "&#9744; ").replace("A) ", "&#9744; ").replace("1)", "&#9744; ").replace("1.)", "&#9744; ").replace("1.", "&#9744; ").replace("a)", "&#9744; ").replace("a) ", "&#9744; ");
        qArrTA = qArrTA.replace("B)", "<BR/>&#9744; ").replace("B) ", "<BR/>&#9744; ").replace("2)", "<BR/>&#9744; ").replace("2.)", "<BR/>&#9744; ").replace("2.", "<BR/>&#9744; ").replace("b)", "&#9744; ").replace("b) ", "&#9744; ");
        qArrTA = qArrTA.replace("C)", "<BR/>&#9744; ").replace("C) ", "<BR/>&#9744; ").replace("3)", "<BR/>&#9744; ").replace("3.)", "<BR/>&#9744; ").replace("3.", "<BR/>&#9744; ").replace("c)", "&#9744; ").replace("c) ", "&#9744; ");
        qArrTA = qArrTA.replace("D)", "<BR/>&#9744; ").replace("D) ", "<BR/>&#9744; ").replace("4)", "<BR/>&#9744; ").replace("4.)", "<BR/>&#9744; ").replace("4.", "<BR/>&#9744; ").replace("d)", "&#9744; ").replace("d) ", "&#9744; ");
        qArrTA = qArrTA.replace("E)", "<BR/>&#9744; ").replace("E) ", "<BR/>&#9744; ").replace("5)", "<BR/>&#9744; ").replace("5.)", "<BR/>&#9744; ").replace("5.", "<BR/>&#9744; ").replace("e)", "&#9744; ").replace("e) ", "&#9744; ");
        qArrTA = qArrTA.replace("F)", "<BR/>&#9744; ").replace("F) ", "<BR/>&#9744; ").replace("6)", "<BR/>&#9744; ").replace("6.)", "<BR/>&#9744; ").replace("6.", "<BR/>&#9744; ").replace("f)", "&#9744; ").replace("f) ", "&#9744; ");
      }

      if(qArrT != ""){
        qArrT = qArrTQ + "?</b><BR/>" + qArrTA;

        if(i > 0){
          qText = qText + "<BR/><BR/>";
        }

        qText = qText + qArrT;
      }
    }

    qText = qText.replaceAll("<b>", "<span class=\"bold\">");
    qText = qText.replaceAll("</b>", "</span>");

    return '<div class=\"normal\">'
    + '<div class=\"quiztext\">' + theText +"</div>"
    + '<div class=\"quiz\">' + qText +"</div>"
    + '</div>';
  }

  const onGenerateClick = (answers) => {
    setLoading(true);

    var json = {
        "language": -1,
        "text": theText,
        "questionCount":questionCount,
        "answerCount":anwserCount
    }
    
    api.postDataJSON("quiz/createTextQuiz", json).then((data) => {
        data = data.replace("Ich habe folgenden Text:", "");
        data = data.replace(/\n/g, "");

        console.log(data);

        setQuizText(data);

        setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  const handleGeneratePDFClick = (event) => {
    generatePDF();
}


const handleSaveClick = (event) => {
    saveText();
}

const saveText = () => {
    const htmlText = "<div class=\"textquiztext\">"+showResult()+"</div>";

    var json = {
        taskHTML: htmlText,
        taskPLAIN: theText,
        typeId: 7,
        subjectId: 3,
        niveau: parseInt(niveau),
        userId: user.getUser().id
    };

    api.postDataJSON("task/save", json).then(() => {
        routing.goTo("/pdf");
    });
}

const generatePDF = () => {
    const htmlTextPDF = "<div class=\"task\"><div class=\"textquiztext\">"+showResult()+"</div></div>";

    user.openModal((<GeneratePDFButton htmlText={htmlTextPDF} onGenerate={() => {
        user.closeModal()
    }} setTopic={topic} />));
}

const handleQuestionCount = (n) => {
  if(isNaN(n)){
    setQuestionCount(n.target.value);
  }else{
    setQuestionCount(n);
  }
}

const handleAnswerCount = (n) => {
  if(isNaN(n)){
    setAnswerCount(n.target.value);
  }else{
    setAnswerCount(n);
  }
}

let itemsQuestions = [];
let optionsQuestions = [];
for (let number = 1; number <= 5; number++) {
  itemsQuestions.push(
        <ToggleButton id={"btn_q_"+number} variant="outline-primary" value={number} onClick={() => handleQuestionCount(number)}>{number}</ToggleButton>
    );
    
    optionsQuestions.push(
        <option value={number}>{number}</option>
    );
}

let itemsAnwesers = [];
let optionsAnwsers = [];
for (let number = 1; number <= 4; number++) {
  itemsAnwesers.push(
        <ToggleButton id={"btn_a_"+number} variant="outline-primary" value={number} onClick={() => handleAnswerCount(number)}>{number}</ToggleButton>
    );
    
    optionsAnwsers.push(
        <option value={number}>{number}</option>
    );
}

  return (
    <div>
      <Row>
          <Card>
              <Card.Body>
                <Card.Title>Text-Quiz</Card.Title>
                <Card.Text>
                    Erstelle ein Quiz zu einem Text
                </Card.Text>
              </Card.Body>
          </Card>
      </Row>
      <div id="niveau" className='showCard'>
          <Row>
                <Niveau
                    niveau={niveau}
                    onChanged={handleNiveau} 
                />
          </Row>
      </div>
      <div id="gen" className='hidden'>
          <Row>
            <GeneratingText
                language={-1}
                niveau={niveau}
                loading={loading}
                onChange={handleTheTextChange}
                onChanged={handleTheTextChanged} 
                setLoading={setLoading}
                maxSentenceWarning={0}
                maxSentenceWarningMessage={""}
            />
          </Row>
      </div>
      <div id="quiz" className='hidden'>
          <Row>
              <Card>
                  <Card.Body>
                      <Card.Title>Erstelle das Quiz</Card.Title>
                      <Card.Text>
                        <Form.Label><b>Anzahl Fagen</b></Form.Label>
                        <ToggleButtonGroup className="quiestionsToggle" type="radio" name="questions" defaultValue={-1}>
                            {itemsQuestions}
                        </ToggleButtonGroup>
                        <Form.Select className="questionsSelect" value={questionCount} size="sm" onChange={handleQuestionCount}>
                            {optionsQuestions}
                        </Form.Select>
                      </Card.Text>
                      <Card.Text>
                        <Form.Label><b>Anzahl Antwortmöglichkeiten</b></Form.Label>
                        <ToggleButtonGroup className="answersToggle" type="radio" name="answers" defaultValue={-1}>
                            {itemsAnwesers}
                        </ToggleButtonGroup>
                        <Form.Select className="answersSelect" value={anwserCount} size="sm" onChange={handleAnswerCount}>
                            {optionsAnwsers}
                        </Form.Select>
                      </Card.Text>
                      <Card.Text>
                          {!loading && (
                            <>
                              <Button variant="primary" onClick={() => { onGenerateClick() }} style={{marginRight:'10px'}}>Quiz generieren</Button>
                            </>      
                          )}
                          {loading && (
                              <Loading word="Einen Moment bitte ..." />
                          )}
                      </Card.Text>
                      {quizText !== "" && (
                          <div>
                              <Card.Text>
                                  <SafeHTMLComponent html={showResult()} />
                              </Card.Text>
                              {user.isLoggedIn() && user.getUser().school.licence.active && (
                                  <Card.Text>
                                      {!loading && (
                                          <div>
                                              <Button disabled={api.loading} variant="primary" onClick={handleSaveClick}>Speichern</Button>
                                              <Button disabled={api.loading} variant="primary" onClick={handleGeneratePDFClick} style={{marginLeft: '10px'}}>PDF generieren</Button>
                                          </div>
                                      )}
                                      {loading && (
                                          <Loading word="Einen Moment bitte ..." />
                                      )}
                                  </Card.Text>
                              )}
                              {!user.isLoggedIn() && (
                                  <div>
                                      <Card.Text>
                                          Für die Generierung von PDFs ist eine Anmeldung erforderlich.
                                      </Card.Text>
                                      <Card.Text>
                                        <Button variant="primary" onClick={() => {user.openRegister()}} style={{marginRight:'10px'}}>Registrieren</Button>
                                        <Button variant="primary" onClick={() => {user.openLoginModal()}}>Anmelden</Button>
                                      </Card.Text>
                                  </div>
                              )}
                              {user.isLoggedIn() && !user.getUser().school.licence.active && (
                                  <div>
                                      <Card.Text>
                                          Für die Generierung von PDFs ist eine aktive Lizenzierung erforderlich.
                                      </Card.Text>
                                      <Card.Text>
                                          <Button variant="primary" onClick={() => {user.openAbo(false)}} style={{marginRight:'10px'}}>Abo abschließen</Button>
                                      </Card.Text>
                                  </div>
                              )}
                          </div>
                      )}               
                  </Card.Body>
              </Card>
          </Row>
      </div>
    </div>
  );
};

export default Textquiz;