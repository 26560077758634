import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import "./Addition.css";
import { Row, Card, Button, Form } from 'react-bootstrap';
import useUtilsService from '../../../../services/utils/utils';
import useApiService from '../../../../services/api/api';
import GeneratingMath from '../../../../components/GeneratingMath/GeneratingMath';
import { useUser } from '../../../../services/user/user';
import useRoutingService from '../../../../services/routing/routing';
import FromTo from '../../../../components/FromTo/FromTo';
import GeneratePDFButton from '../../../../components/GeneratePDFButton/GeneratePDFButton';

const Addition = () => {
  const type = "+";

  const user = useUser();
  const utils = useUtilsService();
  const api = useApiService();
  const routing = useRoutingService();

  const [fromOne, setFromOne] = useState(0);
  const [toOne, setToOne] = useState(0);
  const [fromTwo, setFromTwo] = useState(0);
  const [toTwo, setToTwo] = useState(0);

  const [math, setMath] = useState([]);

  const [gap, setGap] = useState(2);

  const handleFromTo = (f1, t1, f2, t2) => {
      setFromOne(f1);
      setToOne(t1);
      setFromTwo(f2);
      setToTwo(t2);

      utils.fadeIn("gen");
  };

  const handleMath = (m) => {
    setMath(m);
    utils.fadeIn("result");
  }

  const handleGap = (g) => {
    setGap(parseInt(g.target.value));
  }

  const getGap = () => {
    var brs = [];

    for(var i = 0; i < gap; i++){
     brs.push((<br/>));
    }
    
    return brs;
  }

  const showResultSpan = (e, result) => {
    e.target.innerHTML = result;
  }

  const hideResultSpan = (e) => {
    e.target.innerHTML = "";
  }

  const showResult = () => {
    var trs = [];

    for(let i = 0; i < math.length; i++){
      trs.push((
        <tr>
          <td className='mathnumber'>{(i+1)}.</td>
          <td>{math[i].first}</td>
          <td>+</td>
          <td>{math[i].second}</td>
          <td>=</td>
          <td className='mathresulttd'>
            <span className='mathresult' onMouseOver={(e) => {showResultSpan(e, math[i].result)}} onMouseOut={(e) => {hideResultSpan(e)}}>  
              &nbsp;
            </span>
            <span>
              {getGap()}
            </span>
          </td>
        </tr>
      ));
    }

    return (
      <div className='task'>
        <table>
          {trs}
        </table>
      </div>
    );
  }

  const getPlainText = () => {
    var plain = "";

    for(let i = 0; i < math.length; i++){
      plain += math[i].first + " " + type + " " + math[i].second;
      if(i < math.length - 1){
        plain +=  ", ";
      }
    }

    return plain;
  }

  const saveText = (callback) => {
    const htmlText = "<div class=\"math\">"+ReactDOMServer.renderToString(showResult())+"</div>";

    var json = {
        taskHTML: htmlText,
        taskPLAIN: getPlainText(),
        typeId: 3,
        subjectId: 2,
        niveau: 5,
        userId: user.getUser().id
    };

    api.postDataJSON("task/save", json).then(() => {
        callback();
    });
}

  const generatePDF = () => {
    const htmlText = "<div class=\"math\">"+ReactDOMServer.renderToString(showResult())+"</div>";

    user.openModal((<GeneratePDFButton htmlText={htmlText} onGenerate={() => {
        user.closeModal()
    }} setTopic={"Addition"} />));
  }

  const handleGeneratePDFClick = (event) => {
      generatePDF();
  }

  const handleSaveClick = (event) => {
      saveText(() => {
        routing.goTo("/pdf");
      });
  }

  return (
    <div>
      <Row>
          <Card>
              <Card.Body>
                  <Card.Title>Addition</Card.Title>
                  <Card.Text>Addition ist eine mathematische Grundrechenart, bei der zwei Zahlen zu einer Summe zusammen gezählt werden.</Card.Text>
              </Card.Body>
          </Card>
      </Row>
      <div id="niveau" className='showCard'>
          <Row>
            <FromTo
                fromOne={fromOne}
                toOne={toOne}
                fromTwo={fromTwo}
                toTwo={toTwo}
                onChanged={handleFromTo} 
            />
          </Row>
      </div>
      <div id="gen" className='hidden'>
          <Row>
            <GeneratingMath
              math={math}
              fromOne={fromOne}
              toOne={toOne}
              fromTwo={fromTwo}
              toTwo={toTwo}
              type={type}
              onChanged={handleMath}
            />
          </Row>
      </div>
      <div id="result" className='hidden'>
          <Row>
            <Card>
                <Card.Body>
                    <Card.Title>Aufgaben</Card.Title>
                    <Card.Text>
                      <div style={{marginBottom:'30px'}}>
                        <Form.Label>Abstand zwischen den Aufgaben:</Form.Label>
                        <Form.Range value={gap} min={1} max={10} step={1} onChange={handleGap} onClick={handleGap} />
                      </div>
                      {math.length > 0 && (
                        <div>
                          {showResult()}
                        </div>
                      )}
                    </Card.Text>
                      {user.isLoggedIn() && user.getUser().school.licence.active && (
                        <Card.Text>
                          <Button disabled={api.loading} variant="primary" onClick={handleSaveClick}>Speichern</Button>
                          <Button disabled={api.loading} variant="primary" onClick={handleGeneratePDFClick} style={{marginLeft: '10px'}}>PDF generieren</Button>
                        </Card.Text>
                      )}
                      {!user.isLoggedIn() && (
                          <div>
                              <Card.Text>
                                  Für die Generierung von PDFs ist eine Anmeldung erforderlich.
                              </Card.Text>
                              <Card.Text>
                                <Button variant="primary" onClick={() => {user.openRegister()}} style={{marginRight:'10px'}}>Registrieren</Button>
                                <Button variant="primary" onClick={() => {user.openLoginModal()}}>Anmelden</Button>
                              </Card.Text>
                          </div>
                      )}
                      {user.isLoggedIn() && !user.getUser().school.licence.active && (
                          <div>
                              <Card.Text>
                                  Für die Generierung von PDFs ist eine aktive Lizenzierung erforderlich.
                              </Card.Text>
                              <Card.Text>
                                  <Button variant="primary" onClick={() => {user.openAbo(false)}} style={{marginRight:'10px'}}>Abo abschließen</Button>
                              </Card.Text>
                          </div>
                      )}
                </Card.Body>
            </Card>
          </Row>
      </div>
    </div>
    
  );
};

export default Addition;