import React, { useState } from 'react';
import "./Reading.css";
import { Row, Card, Form, Button } from 'react-bootstrap';
import useApiService from '../../../../services/api/api';
import useRoutingService from '../../../../services/routing/routing';
import useUtilsService from '../../../../services/utils/utils';
import GeneratingText from '../../../../components/GeneratingText/GeneratingText';
import Loading from '../../../../components/Loading/Loading';
import Niveau from '../../../../components/Niveau/Niveau';
import { useUser } from '../../../../services/user/user';
import GeneratePDFButton from '../../../../components/GeneratePDFButton/GeneratePDFButton';

const Reading = () => {
  const user = useUser();
  const routing = useRoutingService();
  const api = useApiService();
  const utils = useUtilsService();

  const [type, setType] = useState("-1");
  const [niveau, setNiveau] = useState("-1");

  const [theText, setTheText] = useState('');
  const [topic, setTopic] = useState('');

  const [loading, setLoading] = useState(false);

  const handleNiveau = (n) => {
      setNiveau(n);
      utils.fadeIn("gen");
  };

  const handleType = (t) => {
    setType(t.target.value);
  }

    const handleTheTextChange = () => {
        utils.fadeOut("type");
        utils.fadeOut("gaps");
        utils.fadeOut("create");
        utils.fadeOut("clozetextResult");

        setType("-1");
    }

    const handleTheTextChanged = (newText, topic) => {
        setTheText(newText);
        setTopic(topic);

        utils.fadeIn("type");
    }

    const getSentenceCount = () => {
        return theText.split(". ").length;
    }

  const SafeHTMLComponent = ({ html }) => {
    return (
      <div>
        <div className='result' dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  };

  const showResult = () => {
    let returnText = theText;

    switch(type){
      case "-1" || -1:
          returnText = '<div class=\"normal\">' + returnText + '</div>';;
      break;

      case "1" || 1:
        returnText = '<div class=\"horizontal\">' + returnText + '</div>';;
      break;

      case "2" || 2:
        returnText = '<div class=\"vertikal\">' + returnText + '</div>';;
      break;

      case "3" || 3:
        returnText = '<div class=\"halbiert\">' + returnText + '</div>';;
      break;

      case "4" || 4:
        returnText = '<div class=\"pyramid pyramid-left\">' + createPyramid(returnText) + '</div>';;
      break;

      case "5" || 5:
        returnText = '<div class=\"pyramid pyramid-center\">' + createPyramid(returnText) + '</div>';;
      break;

      case "6" || 6:
        returnText = '<div class=\"pyramid pyramid-right\">' + createPyramid(returnText) + '</div>';;
      break;

      case "7" || 7:
        returnText = '<span class=\"\">' + returnText + '</span>';;
      break;
    }

    return returnText;

    function createPyramid(text) {
        const words = text.split(' ');
        let lines = '';
        for (let i = 1; i <= words.length; i++) {
            lines += words.slice(0, i).join(' ') + '<br>';
        }
        return lines;
    }
  }

  const handleGeneratePDFClick = (event) => {
        generatePDF();
    }


    const handleSaveClick = (event) => {
        saveText();
    }

    const saveText = () => {
        const htmlText = "<div class=\"readingtext\">"+showResult()+"</div>";

        var json = {
            taskHTML: htmlText,
            taskPLAIN: theText,
            typeId: 2,
            subjectId: 1,
            niveau: parseInt(niveau),
            userId: user.getUser().id
        };

        api.postDataJSON("task/save", json).then(() => {
            routing.goTo("/pdf");
        });
    }

    const generatePDF = () => {
        const htmlTextPDF = "<div class=\"task\"><div class=\"readingtext\">"+showResult()+"</div></div>";

        user.openModal((<GeneratePDFButton htmlText={htmlTextPDF} onGenerate={() => {
            user.closeModal()
        }} setTopic={topic} />));
    }

  return (
    <div>
      <Row>
          <Card>
              <Card.Body>
                <Card.Title>Lesebeispiele</Card.Title>
                <Card.Text>
                  Ein Lesetext für Schüler ist ein altersgerechter und verständlicher Text, der dazu dient, die Lesekompetenz zu fördern. Er enthält klare Sätze und ein passendes Vokabular, das an das Niveau der Lernenden angepasst ist. Durch das Lesen solcher Texte üben Schüler ihre Fähigkeit, Informationen zu erfassen, Zusammenhänge zu verstehen und ihre Leseflüssigkeit zu verbessern. Lesetexte können informative, erzählende oder beschreibende Inhalte haben und oft folgen Verständnisfragen, um das Gelesene zu reflektieren.
                </Card.Text>
              </Card.Body>
          </Card>
      </Row>
      <div id="niveau" className='showCard'>
          <Row>
                <Niveau
                    niveau={niveau}
                    onChanged={handleNiveau} 
                />
          </Row>
      </div>
      <div id="gen" className='hidden'>
          <Row>
            <GeneratingText
                language={-1}
                niveau={niveau}
                loading={loading}
                onChange={handleTheTextChange}
                onChanged={handleTheTextChanged} 
                setLoading={setLoading}
                maxSentenceWarning={1}
                maxSentenceWarningMessage={"Hinweis: Pyramidentexte können nur mit Texten erstellt werden, die aus nur einem Satz bestehen."}
            />
          </Row>
      </div>
      <div id="type" className='hidden'>
          <Row>
              <Card>
                  <Card.Body>
                      <Card.Title>Art des zu lesenden Textes</Card.Title>
                      <Card.Text>
                          <Form.Select value={type} size="sm" onChange={handleType}>
                              <option value="-1">Bitte Wählen</option>
                              <option value="1">Horizontal Gespiegelt</option>
                              <option value="2">Vertikal Gespiegelt</option>
                              {false && (
                                <option value="3">Halbierter Text</option>
                              )}
                              {getSentenceCount() == 1 && (
                                <>
                                    <option value="4">Pyramide Linkbündig</option>
                                    <option value="5">Pyramide Mittig</option>
                                    <option value="6">Pyramide Rechtsbündig</option>
                                </>
                              )}
                          </Form.Select>
                      </Card.Text>
                        {theText !== "" && (
                            <div>
                                <Card.Text>
                                    <SafeHTMLComponent html={showResult()} />
                                </Card.Text>
                                {user.isLoggedIn() && user.getUser().school.licence.active && (
                                    <Card.Text>
                                        {!loading && (
                                            <div>
                                                <Button disabled={api.loading} variant="primary" onClick={handleSaveClick}>Speichern</Button>
                                                <Button disabled={api.loading} variant="primary" onClick={handleGeneratePDFClick} style={{marginLeft: '10px'}}>PDF generieren</Button>
                                            </div>
                                        )}
                                        {loading && (
                                            <Loading word="Einen Moment bitte ..." />
                                        )}
                                    </Card.Text>
                                )}
                                {!user.isLoggedIn() && (
                                    <div>
                                        <Card.Text>
                                            Für die Generierung von PDFs ist eine Anmeldung erforderlich.
                                        </Card.Text>
                                        <Card.Text>
                                          <Button variant="primary" onClick={() => {user.openRegister()}} style={{marginRight:'10px'}}>Registrieren</Button>
                                          <Button variant="primary" onClick={() => {user.openLoginModal()}}>Anmelden</Button>
                                        </Card.Text>
                                    </div>
                                )}
                                {user.isLoggedIn() && !user.getUser().school.licence.active && (
                                    <div>
                                        <Card.Text>
                                            Für die Generierung von PDFs ist eine aktive Lizenzierung erforderlich.
                                        </Card.Text>
                                        <Card.Text>
                                            <Button variant="primary" onClick={() => {user.openAbo(false)}} style={{marginRight:'10px'}}>Abo abschließen</Button>
                                        </Card.Text>
                                    </div>
                                )}
                            </div>
                        )}               
                  </Card.Body>
              </Card>
          </Row>
      </div>
    </div>
  );
};

export default Reading;